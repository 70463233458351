$red: #FA7375;
$light: #EDEDED;
$lite: #fdfdfd;
$grey: #B9B9B9;
$pink: #FE3E87;
$dark: #241F66;
$page: #515151;
$card: #3e4258;
$theme: #22b3de;
$blue: lighten($theme, 20%);
$marine: #13DBE8;
$white: #fff;
$black: #000;
$back: #10264A;
$line: #0a1c39;
$btn: #a2dcf7;
$yellow: #E0902C;

$price: #0C8EC5;
$green: #98ac18;

$basic: 'Inter Tight', sans-serif;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }
