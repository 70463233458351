/* Base */

html {
  font-family: $basic;
  font-weight: normal;
  font-size: 1rem;
  font-display: swap; }
body {
  width: 100%;
  max-width: 100% !important;
  color: lighten($card,2%);
  font-size: 80%;
  font-family: $basic;
  font-weight: normal;
  font-smooth: always;
  overflow-x: hidden;
  letter-spacing: .05em;
  -webkit-font-smoothing: antialised;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  overflow-x: hidden; }

a {
  color: inherit !important;
  text-decoration: none !important; }

footer {
  margin-top: 40px;
  padding: 40px 0 38px;
  background-color: darken($dark, 10%);
  * {
    color: #eeeeee !important; }
  @include v-xs {
    .text-left, .text-right {
      text-align: center !important; } } }

.page {
  padding: 10vh;
  background: $yellow url(../../assets/images/hero.png) center center no-repeat;
  background-size: cover;
  * {
    color: #fff; }
  &-content {
    * {
      font-size: 1.25em;
      color: #444;
      font-weight: 500;
      line-height: 1.75;
      padding-bottom: 20px; }
    a {
      font-size: 1em;
      color: $theme !important; }
    ul {
      list-style: circle; }
    li {
      font-size: 1em; } } }
