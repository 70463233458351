.section {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  color: #fff !important;
  font-size: 1em;
  font-weight: 600;
  border-radius: 8px;
  transition: all ease-out .5s;
  background-color: #3DB8BC;
  &:hover {
    background-color: $card; } }

.section.agenda {
  padding: 2vh 0 !important;
  min-height: auto !important; }
.choise {
  background: #47587B linear-gradient(99.37deg, #7FD3D7 0%, #3D426B 100%); }
.hero {
  background: $yellow url(../../assets/images/hero.png) center center no-repeat;
  background-size: cover; }
.how {
  margin-top: -60px;
  background-color: #241F66; }
.help {
  background-color: #F7F8F9; }

.m0p0 {
  margin: 0 !important;
  padding: 0 !important; }

.steps {
  position: relative;
  margin-top: 60px;
  z-index: 2 !important;
  &::before {
    display: block;
    position: absolute;
    top: 48px;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
    height: 16px;
    border-radius: 8px;
    content: '';
    background-color: lighten($dark, 10%);
    z-index: -1 !important; }
  .r-c {
    position: relative;
    z-index: 1000 !important;
    border: none !important;
    outline: none !important;
    color: $white !important;
    background-color: $theme !important;
    &.active {
      color: $dark !important;
      background-color: $yellow !important; } } }

.section {
  &:hover {
    * {
      animation: Left 1s .2s; } } }

.title {
  &-big {
    font-size: 7em;
    font-weight: 800; }
  &-large {
    font-size: 3em;
    font-weight: 800;
    &.how {
      font-size: 4em;
      font-weight: 600; } }
  &-medium {
    font-size: 2em;
    font-weight: 600; }
  &-small {
    font-size: 1.45em;
    font-weight: 500; }
  &-mini {
    font-size: 1.25em;
    font-weight: 500; } }

p {
  font-size: 1em; }
